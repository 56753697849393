import React from 'react';
import {useTranslation} from 'react-i18next';
import Ph1 from './Media/Ph1.jpg';
import Ph2 from './Media/Ph2.jpg';
import Ph3 from './Media/Ph3.jpg';
import './Reviews.css';

const photos = {
    Anna: Ph3,
    Igor: Ph1,
    Maria: Ph3,
    Alexey: Ph2,
    Elena: Ph3
};

const ReviewList = () => {
    const {t} = useTranslation();

    const reviews = Object.keys(t('Reviews', {returnObjects: true})).map(key => ({
        name: t(`Reviews.${key}.name`),
        photo: photos[key],
        text: t(`Reviews.${key}.text`)
    }));

    return (
        <div className="review-container">
            {reviews.map((review, index) => (
                <div className="review" key={index}>
                    <div className="review-header">
                        <div className="review-photo">
                            <img src={review.photo} alt={review.name}/>
                        </div>
                        <div className="review-name">
                            <p>{review.name}</p>
                        </div>
                    </div>
                    <div className="review-divider"></div>
                    <div className="review-text">
                        <p>{review.text}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReviewList;
