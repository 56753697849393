import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './Text_content/en.json';
import ru from './Text_content/ru.json';
import kk from './Text_content/kk.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en
        },
        ru: {
            translation: ru
        },
        kk: {
            translation: kk
        }
    },
    lng: 'ru',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
