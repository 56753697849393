import React, {useEffect, useState} from 'react';
import "./Header.css";
import {useTranslation} from "react-i18next";
import Earth from "./Media/Icon_Earth.svg";
import Logo_s from "./Media/Logo.png";

function LanguageSwitcher() {
    const {i18n} = useTranslation();
    const [show, setShow] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShow(false);
    };

    useEffect(() => {
        const handleScroll = () => setShow(false);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div
            className="LanguageSwitcher"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <div className="ButtonSwitcher">
                <img src={Earth} alt="Earth"/>
            </div>
            {show && (
                <div className="MenuSwitcher">
                    <button className="ButtonLanguage" onClick={() => changeLanguage('ru')}>Русский</button>
                    <button className="ButtonLanguage" onClick={() => changeLanguage('kk')}>Қазақша</button>
                    <button className="ButtonLanguage" onClick={() => changeLanguage('en')}>English</button>
                </div>
            )}
        </div>
    );
}

const FullWidthBlock = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {t} = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 650) setIsOpen(false);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleItems = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    return (
        <div id="Header_body">
            <LanguageSwitcher/>
            <div id="Header" className={`full-width-block ${isOpen ? 'expanded' : 'collapsed'}`}
                 onMouseLeave={closeMenu}>
                <div id="Media_block">
                    <img src={Logo_s} id="Logo_s"/>
                    <button id="Menu_button" className={`toggle-button ${isOpen || windowWidth > 650 ? 'hide' : ''}`}
                            onClick={toggleItems}>
                        {isOpen ? t('Header.close') : t('Header.menu')}
                    </button>
                </div>
                <div id="Link_block" className={`items-list ${isOpen || windowWidth > 650 ? 'show' : ''}`}>
                    <a href="#About" className="Link" onClick={closeMenu}>
                        <p>{t('Header.about')}</p>
                    </a>
                    <a href="#Partners" className="Link" onClick={closeMenu}>
                        <p>{t('Header.partners')}</p>
                    </a>
                    <a href="#Contacts" className="Link" onClick={closeMenu}>
                        <p>{t('Header.contacts')}</p>
                    </a>
                </div>
            </div>
            <div id="Header_border"></div>
        </div>
    );
};

export default FullWidthBlock;