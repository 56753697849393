import React, {useState} from 'react';
import './FormForClients.css';
import {postData} from "./util";

const Modal = ({caseType, closeModal}) => {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name1: '',
        name2: '',
        name3: '',
        contact1: '',
        contact2: '',
        contact3: ''
    });

    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleButtonClick = () => {
        const {name1, name2, name3, contact1, contact2, contact3} = formData;
        const postDataObject = {name1, name2, name3, contact1, contact2, contact3};

        postData(`/api/call_buyps/call_buyps?type=${name1} ${name2} ${name3}&contact=${contact1} ${contact2} ${contact3}`, postDataObject)
            .then(response => {
                console.log(response);
                setSubmitted(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const renderContent = () => {
        switch (caseType) {
            case 'email':
                return (
                    <div className='Input_block'>
                        <h2 className="Title">Написать на почту</h2>
                        <input className="Input" id="name2" type="text" placeholder="  Имя"
                               onChange={handleInputChange}/>
                        <input className="Input" id="contact2" type="text" placeholder="  abcdifjl@mail.com"
                               onChange={handleInputChange}/>
                    </div>
                );
            case 'phone':
                return (
                    <div className='Input_block'>
                        <h2 className="Title">Заказать звонок</h2>
                        <input className="Input" id="name3" type="text" placeholder="  Имя"
                               onChange={handleInputChange}/>
                        <input className="Input" id="contact3" type="text" placeholder="  +7 ххх ххх хх хх"
                               onChange={handleInputChange}/>
                    </div>
                );
            case 'telegram':
                return (
                    <div className='Input_block'>
                        <h2 className="Title">Введите ник Телеграм</h2>
                        <input className="Input" id="name1" type="text" placeholder="  Имя"
                               onChange={handleInputChange}/>
                        <input className="Input" id="contact1" type="text" placeholder="  @username"
                               onChange={handleInputChange}/>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                {submitted ? (
                    <>
                        <h2 className="Title">Успешно отправлено</h2>
                        <button className="Button" id="Cl" onClick={closeModal}>Закрыть</button>

                    </>
                ) : (
                    <>
                        {renderContent()}
                        <div className="Buttons_block">
                            <button className="Button" id="Ent" onClick={handleButtonClick}>Отправить</button>
                            <button className="Button" id="Cl" onClick={closeModal}>Закрыть</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Modal;
