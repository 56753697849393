import React from 'react';
import './Block_content.css';

const BlockContent = ({title, subtitle, description, imgSrc, bgColor, textAlign}) => {
    return (
        <div className={`content-block ${textAlign === 'left' ? 'reverse' : ''}`}>
            <img src={imgSrc} className="content-image" alt="block image"/>
            <div className={`text-block ${textAlign}`} style={{backgroundColor: bgColor}}>
                <div className="text-content">
                    <p className="title">{title}</p>
                    <p className="subtitle">{subtitle}</p>
                    {Array.isArray(description) ? description.map((desc, index) => (
                        <p key={index} className="description">{desc}</p>
                    )) : <p className="description">{description}</p>}
                </div>
            </div>
        </div>
    );
};

export default BlockContent;
